<template>
  <div class="pagesuixintao">
    <div class="pagesuixintao-content">
      <div class="box img2">
        <ul class="line">
          <li class="line-item">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/aqy02.png" />
          </li>
          <li class="line-item">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/blbl02.png" />
          </li>
          <li class="line-item">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/tx02.png" />
          </li>
          <li class="line-item">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/xmly02.png" />
          </li>
          <li class="line-item">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/blbl02.png" />
          </li>
          <li class="line-item">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/wyy02.png" />
          </li>
          <li class="line-item">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/yk02.png" />
          </li>

          <li class="line-item">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/lrts.png" />
          </li>
          <li class="line-item">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/ty02.png" />
          </li>
          <li class="line-item">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/xl02.png" />
          </li>
          <li class="line-item">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/pptv02.png" />
          </li>

          <li class="line-item">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/kw02.png" />
          </li>
          <li class="line-item">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/qtfm02.png" />
          </li>
          <li class="line-item">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/kg02.png" />
          </li>
        </ul>
        <van-form @submit="onSubmit">
          <van-field
            v-model="form.phone"
            type="tel"
            maxlength="11"
            placeholder="请输入手机号"
          />
          <van-field
            v-model="form.code"
            type="number"
            center
            maxlength="6"
            autocomplete="one-time-code"
            placeholder="请输入验证码"
            class="input-code"
          >
            <template slot="right-icon">
              <div class="code" v-show="sendAuthCode" @click="getAuthCode">
                获取验证码
              </div>
              <div class="code" v-show="!sendAuthCode">
                <span>{{ auth_time }}秒</span>重新获取
              </div>
            </template>
          </van-field>
        </van-form>
        <img
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/submit.png"
          class="submit"
          @click="onSubmit"
        />
        <div class="checked">
          <img
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/yes.png"
            v-show="checked"
            @click="checked = false"
          />
          <img
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/no.png"
            v-show="!checked"
            @click="checked = true"
          />
          <span class="test">我已详细阅读并同意</span>
          <span class="blue">随心淘服务协议</span>
        </div>
      </div>
      <div class="guize">
        <div class="title">1.活动内容：</div>
        <div class="paragraphs">
          订购随心淘会员，19.9元/月包含每30天可选1款app会员权益，到期前24小时内将自动扣费续订。
        </div>
        <div class="paragraphs">
          例如张三在2023年3月15日 10:56分订购了随心淘会员，那么在2023年3月15日
          10:56分～2023年4月13日 10:55期间，张三可任选1款会员权益。且若张三在2023年4月12日
          10:55前未退订服务，那么在2023年4月12日 10:55～2023年4月13日
          10:55期间将自动扣费下一个30天计费周期的费用。
        </div>
        <div class="title">2.生失效方式：</div>
        <div class="paragraphs">
          订购立即生效，当前计费周期费用（30天）一次性扣除，下一个计费周期自动续订，退订需在到期前24小时。
        </div>
        <div class="title">3.会员权益领取：</div>
        <div class="paragraphs">
          会员权益每个计费周期（30天）限领1次，逾期作废。<template
            v-if="appName == 'huanxinxiang'"
            >领取权益可关注【秋末权益管家】微信公众号，进入【会员中心】登陆手机号后领取</template
          >
        </div>
        <div class="title"></div>
        <div class="paragraphs">
          退订用户需在会员权益到期前24小时联系取消，在下一个计费周期生效，且取消后3个月内不能再次订购。
        </div>
        <div class="title">5.退订方式</div>

        <div class="paragraphs">
          支付宝：【我的】-【设置】-【支付设置】-【免密支付/自动扣款】，选择随心淘取消订阅
          微信：【我的】-【服务】-【钱包】-【支付设置】-【自动续费】，选择随心淘取消订阅
        </div>
        <div class="title">6.会员权益范围：</div>
        <div class="paragraphs">
          包括爱奇艺月卡、腾讯视频月卡、腾讯体育月卡、优酷月卡、芒果月卡、迅雷月卡、哔哩哔哩月卡、PPTV月卡、喜马拉雅月卡、懒人听书月卡、网易云音乐、酷我音乐月卡、蜻蜓FM月卡等权益，权益内容可能有调整，请以领取页面为准。
        </div>
        <div class="paragraphs">7.如对活动有疑问或更多需求，请咨询在线客服或拨打</div>
        <div class="paragraphs">{{ setCall }}。</div>
      </div>
    </div>
    <!-- 引导浏览器打开 -->
    <div class="dialog" v-if="state">
      <div class="guide">
        <img src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/guide.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { codeDlipayPay, getCode } from "@/api/index";
import { isWeixin } from "@/utils/common";
import { setAppName } from "@/utils/common";

export default {
  data() {
    return {
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      form: {
        phone: "",
        code: "",
      },
      checked: false,
      state: false,
      ip: "",
      appName: "",
    };
  },
  created() {
    this.appName = setAppName();
    if (isWeixin()) {
      this.state = true;
    }
    const { type = "" } = this.$route.query;
    this.ip =
      type == "test"
        ? "https://test-huanxinxiang.ai-me.cn"
        : "https://huanxinxiang.ai-me.cn";
  },
  computed: {
    setCall() {
      const call = {
        // 秋末
        huanxinxiang: "4001189599",
        // 海络
        "huanxinxiang-hailuo": "4001002501",
        // 铮行
        "huanxinxiang-zhengxing": "4001180238",
      };
      return call[this.appName];
    },
  },
  methods: {
    // 验证码
    async getAuthCode() {
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone } = this.form;
      if (!filterPhone.test(phone)) {
        this.$dialog.alert({ message: "手机号错误" });
        return;
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const res = await getCode(this.ip, { phone_number: phone });
      this.$toast.clear();
      if (res.code != 0) {
        this.$dialog.alert({ message: res.message });
      } else {
        // 设置倒计时秒
        this.sendAuthCode = false;
        this.auth_time = 60;
        let auth_timetimer = setInterval(() => {
          this.auth_time--;
          if (this.auth_time <= 0) {
            this.sendAuthCode = true;
            clearInterval(auth_timetimer);
          }
        }, 1000);
      }
    },
    async onSubmit() {
      let filtercode = /^\d{6}$/;
      const { phone, code } = this.form;
      if (!filtercode.test(code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      } else if (!this.checked) {
        this.$toast({
          duration: 8000,
          message: "请阅读并同意随心淘服务协议",
        });
      } else {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          mask: true,
          message: "加载中...",
        });
        const res = await codeDlipayPay(this.ip, {
          phone_number: phone,
          phone_code: code,
          item_name: "视听会员",
        });
        this.$toast.clear();
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message,
          });
        } else {
          location.href = `alipays://platformapi/startapp?appId=20000067&url=${encodeURIComponent(
            res.sign_str
          )}`;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagesuixintao {
  background: #ff475d url("https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/bg.png")
    100% top/contain no-repeat;
  padding: 455px 0 120px;
  box-sizing: border-box;

  &-content {
    .box {
      width: 702px;
      margin: 0 auto;
      padding: 0 10px;
      box-sizing: border-box;
      &.img1 {
        background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/fromBG1.png")
          100% top/contain no-repeat;
        height: 815px;
      }
      &.img2 {
        height: 921px;
        background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/fromBG2.png")
          100% top/contain no-repeat;
      }

      .line {
        display: flex;
        height: 386px;
        flex-direction: column;
        flex-wrap: wrap;
        overflow-y: auto;
        padding: 110px 0px 0;
        margin: 0 23px;

        &-item {
          width: 157px;
          height: 174px;
          margin-right: 2px;
          margin-bottom: 8px;
          list-style-type: none;
          display: inli ne-block;
          border-radius: 5px;

          img {
            width: 100%;
          }
        }
      }

      ::v-deep .van-form {
        background: transparent;
        padding-top: 34px;
        height: auto;

        .van-cell {
          width: 650px;
          height: 88px;
          border: solid 2px #ffd6ce;
          border-radius: 12px;
          margin: 0 auto;
          overflow: hidden;
          box-sizing: border-box;
          padding: 0 0 0 22px;

          &.input-code {
            margin-top: 20px;

            .code {
              width: 207px;
              height: 88px;
              line-height: 88px;
              text-align: center;
              background-image: linear-gradient(
                  140deg,
                  #ff9753 0%,
                  #ff774a 62%,
                  #ff5641 95%
                ),
                linear-gradient(#ff9753, #ff9753);
              background-blend-mode: normal, normal;
              border-radius: 0px 12px 12px 0px;
              border: solid 2px #ffd6ce;
              color: #ffffff;
              font-size: 30px;
              font-weight: normal;
            }
          }

          .van-field__body {
            height: 100%;

            input {
              height: 100%;
            }
          }

          &::after {
            border: none;
          }

          .van-field__control {
            &::-webkit-input-placeholder {
              font-size: 30px;
              font-weight: normal;
              color: rgba(175, 62, 81, 0.5);
            }
          }
        }
      }

      .submit {
        width: 629px;
        padding: 30px 0 14px;
        display: block;
        margin: 0 auto;
      }

      .checked {
        display: flex;
        color: #c1696a;
        font-size: 24px;
        justify-content: center;
        align-items: center;
        font-weight: normal;

        img {
          width: 28px;
          vertical-align: middle;
        }

        .test {
          padding-left: 10px;
        }

        .blue {
          color: #ff122f;
        }
      }
    }

    .guize {
      padding: 99px 25px 0;
      width: 702px;
      height: 1270px;
      margin: 21px auto 0;
      background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/guize.png")
        100% top/contain no-repeat;
      box-sizing: border-box;
      line-height: 38px;

      .title {
        color: #fef6c6;
        font-size: 24px;
        font-weight: normal;
      }

      .paragraphs {
        font-size: 22px;
        font-weight: normal;
        letter-spacing: 0px;
        color: #fadcdc;
        // padding-bottom: 18px;
      }
    }
  }
  .dialog {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 1, 0, 0.6);
    z-index: 2;

    .guide {
      text-align: right;
      img {
        width: 332px;
        margin: 30px 60px 0 0;
      }
    }
  }
}
</style>
